@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.image-button-content {
    &:hover {
        text-decoration: none;
        /* stylelint-disable-next-line */
        .image-button-content-btn {
            background-color: var(--background-primary);
            box-shadow: inset 2px 2px 0 var(--background-primary), inset -2px -2px 0 var(--background-primary);
            color: #fff;
            outline: none;
            text-decoration: none;
        }
    }

    .image-button-content-btn {
        background-color: #fff;
        color: var(--background-secondary);
    }
}

.image-button-content-btn {
    font-family: var(--font-primary);
    font-size: var(--text-sm);
    font-weight: bold;
    line-height: 20px;
}
