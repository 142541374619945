@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./imageButton__typography";

.image-button {
    cursor: pointer;
    position: relative;
    transition: transform 0.4s;

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
    }
}

.image-button-content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    text-decoration: none;
    top: 0;
    width: 100%;
    z-index: 10;
}

.image-button-content-btn {
    align-items: center;
    border: 0;
    border-radius: 0;
    box-shadow: inset 2px 2px 0 var(--border-secondary), inset -2px -2px 0 var(--border-secondary);
    display: flex;
    height: 56px;
    justify-content: center;
    min-height: 56px;
    min-width: 100px;
    overflow: hidden;
    padding: 18 32px;
    text-overflow: ellipsis;
    transition: color 0.4, background-color 0.4;
    transition: transform 0.4s;
    white-space: nowrap;
    width: fit-content;

    /* stylelint-disable-next-line */
    &:hover {
        box-shadow: inset 2px 2px 0 var(--border-primary), inset -2px -2px 0 var(--border-primary);
    }

    /* stylelint-disable-next-line */
    &:hover {
        transform: scale(1.03);
    }

    /* stylelint-disable-next-line */
    &:focus {
        text-decoration: none;
    }
}

.image-button-img {
    width: 100%;
}
